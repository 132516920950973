import React from 'react';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom'

import '../../assets/css/intro.scss'; 

import Contact from './Contact';
import Typing from './Typing';

function Intro() {

    return (
        <div className="segOne">
            <div className="segOneLeft">
                <h2>Bespoke software and lasting<br />customer relationships</h2>
                <p>At EJ Software, we're more than just a team of experts.
                    We're your dedicated partners in turning your software
                    aspirations into reality. Whether you're envisioning a
                    streamlined internal system or a robust, scalable platform
                    to power your entire business, we've got you covered.
                </p>

                <div className="centerMe">
                    <Button className="newButton introButton" as={Link} to="/services">Learn More</Button>
                </div>
            </div>
            <div className="segOneRight">
                <Contact />
            </div>
        </div>
    );
};

export default Intro;
